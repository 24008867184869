<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Crédito - Ver </strong>
            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/credito/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">

              <b-row >
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    <strong>DATOS DEL CRÉDITO <b-badge v-if="credit.credit_plus == 1" variant="primary">PLUS</b-badge></strong>
                  </div>
                </b-col>
                <b-col lg="6">
                  <b-form-group>
                    <label>Cliente: </label>
                    <v-select disabled placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="text" v-model="client" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Crédito:">
                    <b-form-input class="text-center" v-model="credit.code" disabled></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo de Crédito:">
                    <b-form-input class="text-left" readonly v-model="credit.product_name"></b-form-input>
                    <small v-if="errors.id_product"  class="form-text text-danger" >Seleccione un Crédito</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="TEA:">
                    <b-form-input class="text-right" readonly v-model="credit.financial_data_tea"></b-form-input>
                    <small v-if="errors.id_financial_data"  class="form-text text-danger" >Seleccione una TEA</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Tipo de Programa:">
                    <b-form-select disabled v-model="credit.id_product_type" :options="products_type"></b-form-select>
                    <small v-if="errors.id_product_type"  class="form-text text-danger" >Seleccione un Programa</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input class="text-right" disabled v-model="credit.amount" type="number" step="any"></b-form-input>
                    <small v-if="errors.amount"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Cuotas:">
                    <b-form-input class="text-center" disabled v-model="credit.dues" type="number" step="any"></b-form-input>
                    <small v-if="errors.dues"  class="form-text text-danger" >Ingrese una cuota</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Desembolso:">
                    <b-form-input class="text-center" disabled v-model="credit.disbursement_date" type="date" ></b-form-input>
                    <small v-if="errors.disbursement_date"  class="form-text text-danger" >Ingrese una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Pago:">
                    <b-form-input class="text-center" disabled v-model="credit.payment_date" type="date" ></b-form-input>
                    <small v-if="errors.payment_date"  class="form-text text-danger" >Ingrese una fecha</small>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="row justify-content-center">
              
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    <strong>DATOS DE LOS GARANTES</strong> 
                  </div>
                </b-col>
                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="10%" class="text-center">T. D.</th>
                          <th width="15%" class="text-center">Nro Documento</th>
                          <th width="50%" class="text-center">Nombres</th>
                          <th width="15%" class="text-center">Garantes de</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credit.guarantors" :key="it">
                        <tr>
                          <td class="text-center">{{ it + 1 }}</td>
                          <td class="text-left"> {{ CodeDocumentType(item.document_type)  }}</td>
                          <td class="text-left"> {{ item.document_number  }}</td>
                          <td class="text-left"> {{ item.name  }}</td>
                          <td class="text-left"> {{ item.document_type  }} Prestamos</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="w-100 text-center">
                    <small v-if="errors.guarantor"  class="form-text text-danger" >Ingrese un garante</small>
                  </div>
                  
                </b-col>
              </b-row>
             
              <b-row >
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    <strong>DOCUMENTOS ADJUNTOS</strong> 
                  </div>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Anexos:">
                     <b-button class="form-control" :disabeld="credit.annexes.length == 0" @click="OpenAnnexes" type="button" variant="info">Ver Anexos</b-button>
                  </b-form-group>
                </b-col>

                <b-col md="5" >
                  <b-form-group label="Documentos Adjuntados:">
                    <b-form-checkbox-group   v-model="credit.uploaded_documents" :options="requirements" name="flavour-1"></b-form-checkbox-group>
                    <small v-if="errors.uploaded_documents"  class="form-text text-danger" >Seleccione los documentos adjuntados</small>
                  </b-form-group>
                </b-col>
                
                <b-col md="3" >
                  <b-form-group label="Cambiar:">
                     <b-form-file  accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                    <small v-if="errors.annexes"  class="form-text text-danger" >Seleccione un documento</small>
                  </b-form-group>
                </b-col>

                <b-col md="2" >
                  <b-form-group label=".">
                     <b-button class="form-control" @click="ChangeAnnexes" type="button" variant="primary">Reemplazar Anexo</b-button>
                  </b-form-group>
                </b-col>


                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                     <strong>OBSERVACIONES</strong>
                  </div>
                </b-col>
                <b-col md="12">
                  <b-form-group label="">
                    <b-form-textarea rows="5" readonly v-model="credit.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>



              <b-row class="row justify-content-center">
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    <strong>CRONOGRAMA DE ṔAGO</strong> 
                  </div>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="6%" class="text-center">Cuota</th>
                          <th width="9%" class="text-center">Fecha</th>
                          <th width="6%" class="text-center">Capital</th>
                          <th width="6%" class="text-center">Amort. <br> Crédito</th>
                          <th width="6%" class="text-center">Interes</th>
                          <th width="6%" class="text-center">Saldo <br> Capital </th>
                          <th width="6%" class="text-center">Estado</th>
                          <th width="6%" class="text-center">Amort. <br> Cuota</th>
                          <th width="6%" class="text-center">Dias <br> Moros.</th>
                          <th width="6%" class="text-center">Morosidad</th>
                          <th width="6%" class="text-center">Desc. <br> Interes</th>
                          <th width="6%" class="text-center">Desc. <br> Morosidad</th>
                          <th width="6%" class="text-center">Cuota Final</th>
                          <th width="9%" class="text-center">Fecha <br> Pago</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credit.credits_details" :key="it">
                        <tr>
                          <td class="text-center">{{ item.quota_number }}</td>
                          <td class="text-right"> {{ item.quota  }}</td>
                          <td class="text-center"> {{ item.date  }}</td>
                          <td class="text-right"> {{ item.capital_balance  }}</td>
                          <td class="text-right"> {{ item.amortization  }}</td>
                          <td class="text-right"> {{ item.interest  }}</td>
                          <td class="text-right"> {{ item.balance  }}</td>
                          <td class="text-center">
                            <b-badge v-if="item.quota_number != 0 && item.payment_status == 1" variant="warning">Pendiente</b-badge>
                            <b-badge v-if="item.quota_number != 0 && item.payment_status == 2" variant="success">Pagado</b-badge>
                            <b-badge v-if="item.quota_number != 0 && item.payment_status == 3" variant="info">Refinanciado</b-badge>
                          </td>
                          <td class="text-right"> {{ item.installment_amortization  }}</td>
                          <td class="text-right"> {{ item.days_delinquency  }}</td>
                          <td class="text-right"> {{ item.delinquency  }}</td>
                          <td class="text-right"> {{ item.discount_interest  }}</td>
                          <td class="text-right"> {{ item.discount_delinquency  }}</td>
                          <td class="text-right"> {{ item.total_payment  }}</td>
                          <td class="text-center"> {{ item.payment_date  }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            
            </b-form>

            <b-form id="Form" autocomplete="off" @submit.prevent="ValidateApproveOrReject">
              <b-row class="row justify-content-center">
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    APROBACIÓN / RECHAZO DEL CRÉDITO
                  </div>
                </b-col>
                <b-col md="10">
                  <b-form-group>
                      <label>Desembolso: </label>
                      <b-form-input v-model="credit.disbursement" disabled></b-form-input>
                    </b-form-group>
                  </b-col>
                <b-col md="2">
                    <b-form-group label="Estado de Crédito">
                      <b-form-select disabled v-model="credit.credit_status" :options="credit_status"></b-form-select>
                    </b-form-group>
                </b-col>

              </b-row>
            </b-form>
          


            <b-row class="row justify-content-center">
                <b-col md="3" >
                  <b-button class="form-control" type="button" @click="OpenPaymentSchedule" variant="info">Cronograma de Pago</b-button>
                </b-col>
            </b-row>



            <ModalClients/>
            <ModalGuarantor/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import EventBus from "@/assets/js/EventBus";
import ModalClients from './../components/ModalClient'
import ModalGuarantor from './../components/ModalGuarantor'
export default {
  name: "CreditEdit",
  props: ["id_credit"],
  components:{
    vSelect,
    ModalClients,
    ModalGuarantor,
  },
  data() {
    return {
      module:'Credit',
      role:3,
      credit: {
        id_credit:'',
        id_branch_office:'',
        id_user:'',
        id_client:'',
        id_product:'',
        id_product_type:'',
        id_financial_data:'',
        code:'',
        amount:'',
        dues:'',
        disbursement_date: moment(new Date()).local().format("YYYY-MM-DD"),
        payment_date: moment(new Date()).local().format("YYYY-MM-DD"),
        now_date: moment(new Date()).local().format("YYYY-MM-DD"),
        payment_capital:'',
        annexes:'',
        uploaded_documents:[],
        observation:'',
        id_disbursement:'',
        id_user_approver:'',
        credit_status:'',
        state:'',
        payment_capital: [],
        guarantors:[],
        credits_details:[],

        product_name: '',
        financial_data_tea:'',
        new_annexes:'',
        disbursement:'',
        credit_plus:'',
	
      },
      products: [],
      products_type:[],
      financial_data: [{value:"", text:"- Seleccione una opción -"}],
      payment_capital:{
        dues: '',
        amount: '',
      },
      credit_status:[
        {value:1, text:'Aprobado'},
        {value:2, text:'Realizado'},
        {value:3, text:'Rechazado'},
        {value:4, text:'Finalizado'},
      ],
      requirements:[],
      client: null,
      clients: [],
      file:null,
      guarantor: null,
      guarantors: [],
      errors:{
        id_client: false,
        id_product: false,
        id_financial_data: false,
        amount: false,
        dues: false,
        payment_date: false,
        payment_capital: false,
        guarantor: false,
        annexes: false,
        uploaded_documents: false,

        id_disbursement:false,
        credit_status:false,
      },

      credits_details: [],
      modal_payment_capital: false,

      disbursement: null,
      disbursements: [],
     
    };
  },
  mounted() {
    EventBus.$on('GetDataClient', (data) => {
      this.client = {value:data.id_client,text:data.name+" - "+data.document_number};
    });
    EventBus.$on('GetDataGuarantor', (data) => {
      this.guarantor = {value:data.id_guarantor,text:data.name+" - "+data.document_number};
    });
    this.ListProducts();
    this.ListRequirements();
    this.ViewCredit();
    this.ListProductsType();
   
  },
  methods: {

    ListProducts,
    ListProductsType,
    ListRequirements,
    ListFinancialData,
    ViewCredit,
    Validate,
    AddCredit,

    CodeDocumentType,

    onFileChange,
    OpenAnnexes,
    ChangeAnnexes,
    SearchDisbursement,

    ValidateApproveOrReject,
    ApproveOrReject,
    OpenPaymentSchedule,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    }
  },
};


function ListProductsType() {
  let me = this;
  let url = me.url_base + "product/list-product-type";
  me.products_type = [{ value: '', text:'- Seleccione una opcion - '}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.products_type.push({value: element.id_product_type, text: element.name });
      });
    }

  });
}

function SearchDisbursement() {
  
  let me = this;
  let url = this.url_base + "disbursement/search";

  let data = {
    id_client: this.credit.id_client,
    id_module: 1, //Prestamo
  };

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  }).then(function (response) {
    me.disbursements = response.data.result;
  })
   
}

function OpenPaymentSchedule() {
  let me = this;
  let url = me.url_base + 'credit-payment-schedule-pdf/'+this.credit.id_credit+"/" + this.user.id_user;
  window.open(url,'_blank');
}

function OpenAnnexes() {
  let me = this;
  let url = me.url_base + this.credit.annexes;
  window.open(url,'_blank');
}

function ChangeAnnexes() {

  let me = this;
  if (this.credit.new_annexes.length == 0) {
    Swal.fire({ icon: 'error', text: 'Seleccione un anexo', timer: 3000,})
    return false;
  }
  let data = new FormData();
  data.append("id_credit", this.credit.id_credit);
  data.append("annexes", this.credit.new_annexes);
  data.append("id_user", this.user.id_user);
  data.append("uploaded_documents", JSON.stringify(this.credit.uploaded_documents));

  let url = me.url_base + "credit/replace-annex";

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit.annexes = response.data.result.annexes;
        me.file = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function ListProducts() {

  let me = this;
  let url = me.url_base + "product/actives";
  me.products = [{ value: '', text:'- Seleccione una opcion - '}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.products.push({value: element.id_product, text: element.name });
      });
    }

  });
}

function ListFinancialData() {

  let me = this;
  me.financial_data = [{ value: '', text:'- Seleccione una opcion - '}];
  me.credit.id_financial_data = '';
  me.credit.payment_capital = [];
  if (me.credit.id_product.length == 0) {
    return false;
  }
  let url = me.url_base + "financial-data/actives/"+me.credit.id_product;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.financial_data.push({value: element.id_financial_data, text: element.tea + "%" });
        if (element.default == 1) {
          me.credit.id_financial_data = element.id_financial_data;
        }
      });
    }

  });
}

function Validate() {

 
  this.errors.id_client = this.client == null ? true : false;
  this.errors.id_product = this.credit.id_product.length == 0 ? true : false;
  this.errors.id_financial_data = this.credit.id_financial_data.length == 0 ? true : false;
  this.errors.amount = this.credit.amount.length == 0 ? true : false;
  this.errors.dues = this.credit.dues.length == 0 ? true : false;
  this.errors.payment_date = this.credit.payment_date.length == 0 ? true : false;
  // this.errors.payment_capital = this.credit.payment_capital.length == 0 ? true : false;
  this.errors.guarantor = this.credit.guarantors.length == 0 ? true : false;
  this.errors.annexes = this.credit.annexes.length == 0 ? true : false;
  this.errors.uploaded_documents = this.credit.uploaded_documents.length == 0 ? true : false;

  
  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_product) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_financial_data) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_capital) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.guarantor) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.annexes) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.uploaded_documents) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar la solicitud de crédito ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddCredit();
    }
  });
}

function AddCredit() {
  let me = this;
  let data = new FormData();
  data.append("id_branch_office", this.user.id_branch_office);
  data.append("id_user", this.user.id_user);
  data.append("id_client", this.client.value);
  data.append("id_product", this.credit.id_product);
  data.append("id_financial_data", this.credit.id_financial_data);
  data.append("code", this.credit.code);
  data.append("amount", this.credit.amount);
  data.append("dues", this.credit.dues);
  data.append("payment_date", this.credit.payment_date);
  data.append("payment_capital", JSON.stringify(this.credit.payment_capital));
  data.append("annexes", this.credit.annexes);
  data.append("uploaded_documents", JSON.stringify(this.credit.uploaded_documents));
  data.append("observation", this.credit.observation);
  data.append("guarantors", JSON.stringify(this.credit.guarantors));

  let url = me.url_base + "credit/add";

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.$router.push({ name: "CreditList" });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function ListRequirements() {

  let me = this;
  let url = me.url_base + "requirement/list-requirement-by-module/1";
  me.requirements = [];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        response.data.result.forEach(element => {
          me.requirements.push({value: element.id_requirement, text: element.name})
        });
      }
    });
}

function ViewCredit() {
  let me = this;
  let id_credit = je.decrypt(this.id_credit);
  let url = me.url_base + "credit/view/"+id_credit;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit.id_credit = response.data.result.id_credit;
        me.credit.id_branch_office = response.data.result.id_branch_office;
        me.credit.id_user = response.data.result.id_user;
        me.credit.id_client = response.data.result.id_client;
        me.credit.id_product = response.data.result.id_product;
        me.credit.id_product_type = response.data.result.id_product_type;
        me.credit.id_financial_data = response.data.result.id_financial_data;
        me.credit.code = response.data.result.code;
        me.credit.amount = response.data.result.amount;
        me.credit.dues = response.data.result.dues;
        me.credit.disbursement_date = response.data.result.disbursement_date;
        me.credit.payment_date = response.data.result.payment_date;
        me.credit.now_date = response.data.result.now_date;
        me.credit.payment_capital = response.data.result.payment_capital;
        me.credit.annexes = response.data.result.annexes;
        me.credit.uploaded_documents = response.data.result.uploaded_documents;
        me.credit.observation = response.data.result.observation;
        me.credit.id_disbursement = response.data.result.id_disbursement;
        me.credit.id_user_approver = response.data.result.id_user_approver;
        me.credit.credit_status = response.data.result.credit_status;
        me.credit.state = response.data.result.state;
        me.credit.payment_capital = response.data.result.payment_capital;
        me.credit.guarantors = response.data.result.guarantors;
        me.credit.credits_details = response.data.result.credits_details;
        me.credit.product_name = response.data.result.product_name;
        me.credit.financial_data_tea = response.data.result.financial_data_tea;
        me.credit.disbursement = response.data.result.disbursement;
        me.credit.credit_plus = response.data.result.credit_plus;
        
        me.client = { value: response.data.result.id_client, text: response.data.result.client_name }

        
        
      }
    });
}

function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code);
}

function onFileChange(e) {
  this.credit.new_annexes = e.target.files[0];
}

function  ValidateApproveOrReject() {

 
this.errors.id_disbursement = this.disbursement == null ? true : false;
this.errors.credit_status = this.credit.credit_status.length == 0 ? true : false;

if (this.credit.credit_status == 1) {
  this.errors.credit_status = true;
  return false;
}

if (this.errors.id_disbursement) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
if (this.errors.credit_status) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
let title = '';
switch (this.credit.credit_status) {
  case 2: title = "Esta seguro de Aprobar la solicitud de crédito ?"; break;
  case 3: title = "Esta seguro de Rechazar la solicitud de crédito ?"; break;
}
let me = this;
Swal.fire({
  title: title,
  text: "No podrás revertir esto!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Si, Estoy de acuerdo!",
}).then((result) => {
  if (result.value) {
    this.ApproveOrReject();
  }
});
}

function ApproveOrReject() {
let me = this;
me.credit.id_user_approver =  this.user.id_user;
me.credit.id_disbursement =  this.disbursement.value;
let data = me.credit;

let url = me.url_base + "credit/approve-or-reject";

me.isLoading = true;
axios({
  method: "PUT",
  url: url,
  data: data,
  headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
})
  .then(function (response) {
    if (response.data.status == 200) {
      me.$router.push({ name: "CreditList" });
      Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
    }else{
      Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
    }
    me.isLoading = false;
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}
</script>